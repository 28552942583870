import PropTypes from 'prop-types';
import { useContext, createContext, useMemo, useCallback, useEffect } from 'react';
import { requestDownlineAsync, downlineSelector, salesSettingsByRepSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/sales-settings';
import { useDispatch, useSelector } from 'react-redux';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';

const { TARGET_ID_VALUE } = planBuilderConstants;

const RepContext = createContext();

const useGetRepOptions = () => useContext(RepContext);

const RepsProvider = ({ children }) => {
  const dispatch = useDispatch();

  const downline = useSelector(downlineSelector);
  const salesSettingsByRep = useSelector(salesSettingsByRepSelector);

  useEffect(
    () => {
      if (salesSettingsByRep.length > 0){
        dispatch(requestDownlineAsync.request({
          page: {
            number: 1,
            size: salesSettingsByRep.length,
          },
          filter: {
            ids: [...salesSettingsByRep.map(({ [TARGET_ID_VALUE]:target_id }) => target_id)],
          },
        }));
      }
    },
    [salesSettingsByRep, dispatch],
  );

  const options = useMemo(() => {
    if (downline) {
      const repIds = salesSettingsByRep.map(({ [TARGET_ID_VALUE]:target_id }) => target_id);

      return downline
        .filter(({ id }) => !repIds.includes(id))
        .map(({ id, name }) => ({
          label: name,
          value: id,
        }));
    }

    return [];
  }, [downline, salesSettingsByRep]);

  const getOptions = useCallback(
    (id) => {
      const matchingRep = downline.find((rep) => rep.id === id);
      const baseOptions = [];

      if (matchingRep) {
        baseOptions.push({
          label: matchingRep.name,
          value: id,
        });
      }

      return [...baseOptions, ...options];
    },
    [downline, options],
  );

  return (
    <RepContext.Provider value={getOptions}>{children}</RepContext.Provider>
  );
};

RepsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RepsProvider, useGetRepOptions };
