import { salesSettingsByRepSelector, downlineSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/sales-settings';
import { isAdminSelector } from '@/redux/auth';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export function useSalesSettingsByDownlineSelector() {
  const salesSettingsByRep = useSelector(salesSettingsByRepSelector);
  const downline = useSelector(downlineSelector);
  const isAdmin = useSelector(isAdminSelector);

  const selector = useCallback(
    () => isAdmin
      ? salesSettingsByRep
      : salesSettingsByRep.filter(
        (salesSetting) => downline.some(
          (rep) => salesSetting.target_id == rep.id,
        ),
      ),
    [isAdmin, salesSettingsByRep, downline],
  );

  return selector;
}
