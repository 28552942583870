import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Select } from '@/components/common';
import { useGetRepOptions } from './RepContext';
import { useDispatch } from 'react-redux';
import { requestDownlineAsync } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/sales-settings';

const EditRep = ({ value, onChange, name }) => {
  const [originalValue] = useState(value);
  const getOptions = useGetRepOptions();

  const [options, setOptions] = useState(getOptions(value));

  useEffect(() => {
    setOptions(getOptions(originalValue));
  }, [getOptions, originalValue]);

  const [timeoutId, setTimeoutId] = useState(null);

  const dispatch = useDispatch();

  const onSearch = (name) => {
    clearTimeout(timeoutId);

    if (!name) {
      return;
    }

    const delayedRequest = setTimeout(() => {
      const params = {
        filter: {
          search: name,
          is_active: true,
        },
      };

      dispatch(requestDownlineAsync.request(params));
    }, 500); // Adjust the delay time as needed

    setTimeoutId(delayedRequest);
  };

  return (
    <Select
      {...{
        onChange,
        value,
        options,
        name,
      }}
      required
      onInputChange={onSearch}
      placeholder="Start to type"
    />
  );
};

EditRep.propTypes = {
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditRep;
